'use client';
import { useEffect } from 'react';

export default function NotFound() {
  useEffect(() => {
    // @ts-ignore
    window.location = '/dashboard/overview';
  }, []);

  return (
    <div className="w-full h-full text-2xl text-grey-7 flex items-center justify-center bg-site-background">
      Redirecting...
    </div>
  );
}
